<template>
	<div class=" size-px-14 color-gray02">
		<div class="pa-20 mt-50">
			<div class="mt-20">
				<button
					class="size-px-16 font-weight-500 color-gray-dark02 "
					@click="toList"
				>{{ $language.tv.subject_tv_list}}</button>
				<div class="mt-15">{{ $language.tv.txt_tv_list}}</div>
			</div>
			<div class="mt-30">
				<button
					class="size-px-16 font-weight-500 color-gray-dark02 "
					@click="toPermission"
				>{{ $language.tv.subject_tv_permission}}</button>
				<div class="mt-15">{{ $language.tv.txt_tv_permission}}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'MafiaTvManage'
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: 'MafiaTvManage'
					, title: this.$language.tv.title_tv_manage
					, type: 'cartel_sub'
					, not_footer: true
					, not_header: false
					, from: {
						name: 'mafia074'
					}
				}
				, items: []
			}
		}
		, computed: {
			item_tv: function(){
				let t = ''
				this.items.filter( (item) => {
					if(item.board_type_code == 'CA00700007'){
						t = item
					}
				})
				return t
			}
		}
		, methods: {
			toList: function(){
				this.$bus.$emit('to', { name: 'MafiaTvManageList', params: { idx: this.$route.params.idx, code: this.item_tv.board_type_code, b_id: this.item_tv.board_number}})
			}
			, toPermission: function(){
				this.$bus.$emit('to', { name: 'MafiaTvManagePermission', params: { idx: this.$route.params.idx, b_id: this.item_tv.board_number}})
			}

			, getData: async function(){
				try{
					const result = await this.$Request({
						method: 'post'
						,url: this.$api_url.api_path.get_cartel_menu
						,data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.items = result.data.board_list
					}else{
						throw result.message
					}
				}catch(e){
					this.$bus.$emit('notify',  { type: 'error', message: e})
					console.log(e)
				}finally {
					this.$bus.$emit('on', false)
				}
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			document.body.scrollTo({ top: 0, behavior: "smooth"})
			this.getData()
		}
	}
</script>